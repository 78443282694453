
import { defineComponent, ref, nextTick } from 'vue';

import upload from '@/components/upload';
import { TFileData } from '@/components/upload/package/type.d';

export default defineComponent({
  components: {
    upload,
  },

  props: {
    name: {
      type: String,
      default: '',
    },
    suffix: {
      type: String,
      default: '',
    },
    taskId: {
      type: String,
      default: '',
    },
  },

  emits: ['update:name', 'save', 'upload'],

  setup(_, { emit }) {
    const isEdit = ref(false);
    const inputRef = ref<HTMLInputElement | null>(null);

    const handleHideEdit = () => {
      isEdit.value = false;
    };

    const handleShowEdit = () => {
      isEdit.value = true;

      nextTick(() => {
        inputRef.value?.focus();
      });
    };

    const handleInput = (v: string) => {
      emit('update:name', v.trim().substr(0, 60));
    };

    const handleSave = () => {
      emit('save');
    };

    const handleSaveAs = () => {
      emit('save', true);
    };

    const handleUpload = (imgs: TFileData[]) => {
      if (imgs && imgs.length > 0) {
        const list = imgs.map((item: TFileData) => item.url);
        emit('upload', list);
      }
    };

    return {
      isEdit,
      handleHideEdit,
      handleInput,
      handleSave,
      handleSaveAs,
      handleUpload,
      handleShowEdit,
      inputRef,
    };
  },

  render() {
    return (
      <>
        <div class="header-bar__placeholder" />
        <div class="header-bar">
          <div class="header-bar__name">
            图片名称：
            {this.isEdit ? <el-input
              ref="inputRef"
              maxLength={60}
              modelValue={this.name}
              style={{ width: '450px', border: 'none' }}
              onInput={this.handleInput}
              onBlur={this.handleHideEdit}
            /> : <>
              <div class="header-bar__name__inner">{this.name}</div>
              <div class="header-bar__edit" onClick={this.handleShowEdit}>
                <i class="iconfont icon_icon_xgmc" />
              </div>
            </>}
          </div>
          <div>图片任务ID：{this.taskId}</div>
          <div class="header-bar__btn">
            <el-button class="header-bar__btn__item">
              添加图片
              <upload type="hidden" onChange={this.handleUpload} />
            </el-button>
            <el-button class="header-bar__btn__item" onClick={this.handleSaveAs}>另存为</el-button>
            <el-button type="primary" class="header-bar__btn__item" onClick={this.handleSave}>保存</el-button>
          </div>
        </div>
      </>
    );
  },
});
