import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_bar = _resolveComponent("header-bar")!
  const _component_canvas_editor = _resolveComponent("canvas-editor")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("main", null, [
      _createElementVNode("div", {
        class: "back",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleBack()))
      }, "返回"),
      _createVNode(_component_header_bar, {
        name: _ctx.name,
        "onUpdate:name": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.name) = $event)),
        suffix: _ctx.suffix,
        taskId: _ctx.taskId,
        onSave: _ctx.handleSave,
        onUpload: _ctx.handleUpload
      }, null, 8, ["name", "suffix", "taskId", "onSave", "onUpload"]),
      _createVNode(_component_canvas_editor)
    ])
  ]))
}