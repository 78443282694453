import { fabric } from 'fabric';
import { isEmpty } from 'lodash-es';
import { round } from 'number-precision';

import { genID } from '@/utils/helper';
import { showFullScreenLoading, hideFullScreenLoading } from '@/core/http/helper';
import { loadImgPromise, EventBus } from './helper';
import { EventNameEnum, extraProps } from '../constant';

export interface ILayer {
  id: string;
  name: string;
}

/**
 * Fabric 的操作全部在这里实现

 */
export default class FabricAction extends EventBus {
  /** fabric 实例 */
  private instance: fabric.Canvas

  /** fabric 实例dom/id */
  private id: string | HTMLCanvasElement

  /** fabric 实例参数 */
  private options: fabric.ICanvasOptions

  /** 当前激活的元素 */
  private activeObject: fabric.Object | undefined

  /** 缩放比 */
  private zoom: number

  /** 最小缩放比 */
  private minZoom: number = 0.2

  /** 最大缩放比 */
  private maxZoom: number = 1

  constructor(id: string | HTMLCanvasElement, options?: fabric.ICanvasOptions) {
    super();
    const defaultOptions: fabric.ICanvasOptions = {
      width: 1200,
      height: 1600,
      preserveObjectStacking: true, // 选中激活的元素最高层
      selectionColor: '#4D88FF', // 选择组件的颜色
      selectionBorderColor: '#4D88FF', // 边框
      selectionDashArray: [5, 5],
      selectionLineWidth: 1,
      controlsAboveOverlay: true,
      hoverCursor: 'pointer',
      selection: false, // 禁止选中多个图层
    };

    const mergeOptions: fabric.ICanvasOptions = {
      ...defaultOptions,
      ...options,
    };

    this.id = id;
    this.options = mergeOptions;
    this.instance = new fabric.Canvas(this.id, this.options);
    this.zoom = this.instance.getZoom();
    // 初始化事件
    this.initEvent();
    // 修改选择元件样式
    this.initControlStyle();
  }

  // 初始化场景，改宽高
  initScene(width: number, height: number) {
    this.setCanvasSize(width, height);
  }

  // 初始化 canvas 信息
  initCanvsData = (json: string) => {
    // 空图层数据不需要加载
    if (isEmpty(JSON.parse(json))) {
      return;
    }
    this.instance.loadFromJSON(json, () => {
      // 原比例展示
      // this.scaleCanvasZoom();
      // 重新初始化信息
      this.emit(EventNameEnum.ACTIVE_OBJECT, undefined);
      this.countObjects();
    });
  }

  // 初始化选中样式
  initControlStyle = () => {
    window.fabric.Object.prototype.set({
      borderColor: '#4D88FF',
      cornerColor: '#4D88FF', // 激活状态角落图标的填充颜色
      cornerStrokeColor: '', // 激活状态角落图标的边框颜色
      cornerSize: 12,
      cornerStyle: 'circle', // rect, circle
    });
  }

  // 初始化监听事件
  initEvent() {
    this.instance.on('mouse:up', (e: fabric.IEvent) => {
      this.setActiveObject(e.target);
    });
  }

  // 初始化 静态死图
  initStaticPoster = async (url: string) => {
    try {
      const dom = await loadImgPromise(url);
      const item = new fabric.Image(
        dom as any,
        {
          crossOrigin: 'anonymous',
          id: `${genID()}`,
          name: '背景图',
        } as any,
      );
      this.instance.add(item).renderAll();
      // 置底
      this.instance.sendToBack(item);
      // 动态设置画布和背景图一致
      if (dom?.width && dom.height) {
        this.setCanvasSize(dom.width, dom.height);
      }
      this.setActiveObject(item);
    } catch (error) {
      console.log('initStaticPoster', error);
    }
  }

  // 得到实例子
  getInstance() {
    if (!this.instance) {
      this.instance = new fabric.Canvas(this.id, this.options);
    }
    return this.instance;
  }

  // 获取当前的活动对象
  getActiveObject() {
    return this.activeObject;
  }

  // 设置当前的活动对象
  setActiveObject(activeObject: fabric.Object | undefined) {
    if (activeObject) {
      this.instance.setActiveObject(activeObject).renderAll();
    }
    this.activeObject = activeObject;
    this.emit(EventNameEnum.ACTIVE_OBJECT, activeObject);
  }

  // 更新一个基础对象
  updateObject(obj: fabric.Object, options: fabric.IObjectOptions) {
    obj.set(options).setCoords();
    this.instance.renderAll();
    // 更新视图
    this.emit(EventNameEnum.ACTIVE_OBJECT, this.activeObject);
  }

  // 渲染一个基础元素
  renderBaseElement<T extends fabric.Object>(obj: T) {
    this.instance.add(obj).renderAll();
    this.setActiveObject(obj);
  }

  // 导出图片
  exportImgData(options?: fabric.IDataURLOptions) {
    const mergeOptions: fabric.IDataURLOptions = {
      format: 'png',
      ...options,
    };
    const dataUrl = this.instance.toDataURL(mergeOptions);
    return dataUrl;
  }

  // 得到当前画布的json
  getCanvasJSON() {
    return JSON.stringify(this.instance.toDatalessJSON(extraProps));
  }

  // 添加一个图片元素
  async addImage(
    element: string | HTMLImageElement | HTMLVideoElement,
    options?: fabric.IImageOptions & Partial<ILayer>,
  ) {
    const mergeOptions: fabric.IImageOptions = {
      crossOrigin: 'anonymous',
      // 默认属性
      ...options,
    };
    try {
      let dom: null | HTMLImageElement = null;
      if (typeof element === 'string') {
        dom = await loadImgPromise(element);
      } else {
        dom = await loadImgPromise((dom as any).src);
      }

      let scaleOptions = {};
      if (dom) {
        const scale = this.calculateImgScale(dom);
        scaleOptions = {
          scaleX: scale,
          scaleY: scale,
        };
      }

      const item = new fabric.Image(dom as any, { ...mergeOptions, ...scaleOptions });
      // 改变大小
      this.renderBaseElement(item);
      this.countObjects();
      return dom;
    } catch (error) {
      console.log('loadImgPromise', error);
      return false;
    }
  }

  // 批量添加图片
  addImages = (list: string[]) => {
    showFullScreenLoading();
    const loop = async (img: string) => {
      await this.addImage(img);
      list.shift();
      if (list.length > 0) {
        loop(list[0]);
      } else {
        hideFullScreenLoading();
      }
    };
    loop(list[0]);
  }

  // 得到当前全部图层数据
  getAllLayerList(list: any[], result: fabric.Object[]) {
    list.forEach((item) => {
      if (item._objects && item._objects.length) {
        this.getAllLayerList(item._objects, result);
      } else {
        result.push(item);
      }
    });
  }

  // 计算的图片大小，不允许大过画布
  calculateImgScale(dom: HTMLImageElement) {
    const canvasWidth = this.instance.getWidth();
    const canvasHeight = this.instance.getHeight();
    const scaleW = round((canvasWidth / dom.width), 2);
    const scaleH = round((canvasHeight / dom.height), 2);
    if (dom.width > canvasWidth && dom.height > canvasHeight) {
      return scaleW < scaleH ? scaleW : scaleH;
    }
    if (dom.width > canvasWidth) {
      return scaleW;
    }
    if (dom.height > canvasHeight) {
      return scaleH;
    }
    return 1;
  }

  // 调整化缩放比
  initZoom(zoomValue: number) {
    this.zoom = zoomValue;
    const mainContent = document.getElementById('main_content');
    if (mainContent) {
      mainContent.style.transform = `scale(${zoomValue},${zoomValue})`;
      const myEvent = new Event('resize');
      window.dispatchEvent(myEvent);
      this.emit(EventNameEnum.UPDATE_ZOOM, zoomValue);
      this.emit(EventNameEnum.ACTIVE_OBJECT, this.activeObject);
      this.autoScrollToCenter();
    }
  }

  // 滚动条自动居中
  autoScrollToCenter() {
    const box1 = document.getElementById('auto_content');
    const box2 = document.getElementById('main_content');
    if (box1 && box2) {
      const b1w = box1.offsetWidth;
      const b2w = box2.offsetWidth;
      const b1h = box1.offsetHeight;
      const b2h = box2.offsetHeight;
      box1.scrollTo((b2w - b1w) / 2, (b2h - b1h) / 2);
      this.emit(EventNameEnum.ACTIVE_OBJECT, this.activeObject);
    }
  }

  // 根据 auto_content 大小来自动缩放 mainContent 大小，也就是canvas的大小
  scaleCanvasZoom(extendSize = 60) {
    const children = document.getElementById('main_content');
    // 调整大小自动根据这个框架来自适应缩放 mainContent 的大小
    const parent = document.getElementById('auto_content');

    const getComputedNum = (dom: any, type: 'width' | 'height') => {
      return parseInt(window.getComputedStyle(dom)[type], 10);
    };

    if (children && parent) {
      const childrenW = getComputedNum(children, 'width');
      const childrenH = getComputedNum(children, 'height');
      const parentW = getComputedNum(parent, 'width') - extendSize;
      const parentH = getComputedNum(parent, 'height') - extendSize;
      // 是否要缩放？
      if (childrenW > parentW || childrenH > parentH) {
        let scale = 1;
        const diffW = childrenW - parentW;
        const diffH = childrenH - parentH;
        // 根据高来缩放
        if (diffW > diffH) {
          scale -= diffW / childrenW;
        } else {
          scale -= diffH / childrenH;
        }
        this.initZoom(scale);
        // 自动调整元素门的位置
      }
    }
  }

  // 设置canvas的宽高
  setCanvasSize(width: number, height: number) {
    this.instance.setWidth(width);
    this.options.width = width;
    this.instance.setHeight(height);
    this.options.height = height;
    this.instance.renderAll();
    // 调整缩放
    this.scaleCanvasZoom();
    const myEvent = new Event('resize');
    window.dispatchEvent(myEvent);
    // 通知更新宽高
    this.emit(EventNameEnum.UPDATE_SIZE, { width, height });
    this.emit(EventNameEnum.ACTIVE_OBJECT, undefined);
  }

  // 层次上移
  onBringForward() {
    this.instance.bringForward(this.instance.getActiveObject());
  }

  // 层次下移
  onSendBackwards = () => {
    this.instance.sendBackwards(this.instance.getActiveObject());
  }

  // 层次置顶
  onBringToFront = () => {
    this.instance.bringToFront(this.instance.getActiveObject());
  }

  // 层次置底
  onSendToBack = () => {
    this.instance.sendToBack(this.instance.getActiveObject());
  }

  // 删除活动对象
  deleteActiveObject = () => {
    if (this.activeObject) {
      this.instance.remove(this.activeObject).renderAll();
      this.emit(EventNameEnum.ACTIVE_OBJECT, this.activeObject);
      this.activeObject = undefined;
      this.setActiveObject(undefined);
      this.countObjects();
    }
  }

  // 复制普通元件
  copyActiveObject = () => {
    const activeObj = this.instance.getActiveObject();
    if (activeObj) {
      activeObj.clone((obj: fabric.Object & ILayer) => {
        obj.id = genID();
        this.instance.add(obj).renderAll();
        this.emit(EventNameEnum.ACTIVE_OBJECT, obj);
        this.activeObject = obj;
        this.setActiveObject(obj);
        this.countObjects();
      });
    }
  }

  // delete activeSelection
  deleteActiveSelection(object: fabric.ActiveSelection & ILayer) {
    // 删除选择的所有物件
    const renmoveList = object._objects;
    renmoveList.forEach((obj) => {
      this.instance.remove(obj).renderAll();
    });
    this.instance.discardActiveObject();
    this.emit(EventNameEnum.ACTIVE_OBJECT, undefined);
  }

  // copy activeSelection
  copyActiveSelection(object: fabric.ActiveSelection & ILayer) {
    try {
      // 复制所有的数据
      const allLayerList: fabric.Object[] = [];
      this.getAllLayerList([object], allLayerList);

      allLayerList.forEach((d) => {
        d.clone((c: any) => {
          c.set({
            id: genID(),
            top: c.top > 0 ? c.top : 0,
            left: c.left > 0 ? c.left : 0,
          }).setCoords();
          this.instance.add(c).renderAll();
        }, extraProps);
      });
    } catch (error) {
      console.error('copyActiveSelection', error);
    }
  }

  // 水平翻转
  onFlipHorizontal() {
    this.instance.getActiveObject().set('flipX', !this.instance.getActiveObject().flipX).setCoords();
    this.instance.renderAll();
  }

  // 垂直翻转
  onFlipVertical() {
    this.instance.getActiveObject().set('flipY', !this.instance.getActiveObject().flipY).setCoords();
    this.instance.renderAll();
  }

  // 统计对象数据
  countObjects() {
    const objects = this.instance.getObjects();
    this.emit(EventNameEnum.COUNT_OBJECTS, objects.length);
  }
}
