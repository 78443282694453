export enum Effect {
  'DARK'= 'dark',
  'LIGHT'= 'light',
}

// 接口数据
export interface IImageData {
  id: string;
  name: string;
}
