/**
 * Event Type, 通过 EventBus 通讯
 */
export enum EventNameEnum {
  /** 激活对象，更新渲染视图 */
  ACTIVE_OBJECT = 'activeObject',
  /** 更新zoom */
  UPDATE_ZOOM = 'updateZoom',
  /** 通知更新当前的canvas画布框高 */
  UPDATE_SIZE = 'updateSize',
  /** 统计图层数据 */
  COUNT_OBJECTS = 'countObjects',
}

export const extraProps = [
  'id',
  'type',
  'name',
  'src',
  'status',
  'lockMovementX',
  'lockMovementY',
  'lockRotation',
  'lockScalingFlip',
  'lockScalingX',
  'lockScalingY',
  'lockSkewingX',
  'lockSkewingY',
  'lockUniScaling',
  'hasControls',
  'selectable',
  'evented',
];

/**
 * 操作按钮
 */
export enum OPERATE_BTN_ENUM {
  COPY = 'copy',
  FLIP_HORRIZONTAL = 'flipHorizontal',
  FLIP_VERTICAL = 'flipVertical',
  SET_TOP = 'setTop',
  SET_BOTTOM = 'setBottom',
  BRING_FORWARD = 'bringForward',
  SEND_BACKWARD = 'sendBackward',
  DELETE = 'delete',
}

export const OPERATE_BTN_LIST = [
  {
    id: OPERATE_BTN_ENUM.COPY,
    tip: '复制',
    icon: 'icon_icon_fz',
  },
  {
    id: OPERATE_BTN_ENUM.FLIP_HORRIZONTAL,
    tip: '水平翻转',
    icon: 'icon_icon_spfz',
  },
  {
    id: OPERATE_BTN_ENUM.FLIP_VERTICAL,
    tip: '垂直翻转',
    icon: 'icon_icon_czfz',
  },
  {
    id: OPERATE_BTN_ENUM.SET_TOP,
    tip: '置顶',
    icon: 'icon_icon_zhiding',
  },
  {
    id: OPERATE_BTN_ENUM.SET_BOTTOM,
    tip: '置底',
    icon: 'icon_icon_zhidi',
  },
  {
    id: OPERATE_BTN_ENUM.BRING_FORWARD,
    tip: '上移一层',
    icon: 'icon_icon_syc',
  },
  {
    id: OPERATE_BTN_ENUM.SEND_BACKWARD,
    tip: '下移一层',
    icon: 'icon_icon_xyc',
  },
  {
    id: OPERATE_BTN_ENUM.DELETE,
    tip: '删除',
    icon: 'icon_icon_sc1',
  },
];
