import http from '@/core/http';
import { DOMAIN_SYSTEM_ENUM } from 'cx-utils';
import {
  IGetMarkerReq,
  IGetMarkerRes,
  IUpdateMarkerReq,
  IUpdateMarkerRes,
  IReplyMarkerReq,
  IDeleteMarkerReq,
  IGetDataByPictureIdReq,
  IGetDataByPictureIdRes,
  ISavePictureByEditorReq,
  ISaveAsPictureByEditorReq,
} from './types';

const server = DOMAIN_SYSTEM_ENUM.tiangong;

const configDefault = {
  loading: true,
  server,
};

/**
 *  批注 - 根据图片ID获取图片批注
 */
export function getMarkerList(params: IGetMarkerReq) {
  return http.get<IGetMarkerRes[]>({
    ...configDefault,
    url: '/moken-portal/web/v1/design-annotation/find-by-picture-id',
    params,
  });
}

/**
 *  批注 - 保存批注信息
 */
export function updateMarker(data: IUpdateMarkerReq) {
  return http.post<IUpdateMarkerRes>({
    ...configDefault,
    url: '/moken-portal/web/v1/design-annotation/save',
    data,
  });
}

/**
 * 批注 - 回复批注
 */
export function replyMarker(data: IReplyMarkerReq) {
  return http.post<IUpdateMarkerRes>({
    ...configDefault,
    url: '/moken-portal/web/v1/design-annotation/reply',
    data,
  });
}

/**
 * 批注 - 删除批注
 */
export function deleteMarker(data: IDeleteMarkerReq) {
  return http.post({
    ...configDefault,
    url: `/moken-portal/web/v1/design-annotation/delete-by-id?id=${data.id}`,
  });
}

/**
 * 编辑器 - 根据图片ID获取图片详情
 */
export function getDataByPictureId(params: IGetDataByPictureIdReq) {
  return http.get<IGetDataByPictureIdRes>({
    ...configDefault,
    url: '/moken-portal/web/v1/design-style-picture/find-by-picture-id',
    params,
  });
}

/**
 * 编辑器 - 保存
 */
export function savePictureByEditor(data: ISavePictureByEditorReq) {
  return http.post<IGetDataByPictureIdRes>({
    ...configDefault,
    url: '/moken-portal/web/v1/design-style-picture/save',
    data,
  });
}

/**
 * 编辑器 - 另存为
 */
export function saveAsPictureByEditor(data: ISaveAsPictureByEditorReq) {
  return http.post<IGetDataByPictureIdRes>({
    ...configDefault,
    url: '/moken-portal/web/v1/design-style-picture/save-another',
    data,
  });
}
