
import { defineComponent, reactive, ref, provide, onMounted, onUnmounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { fabric } from 'fabric';
import { isEmpty } from 'lodash-es';

import FabricAction from './utils/action';
import { EventNameEnum } from './constant';
import { getTrimPNG, b64toFile } from './utils/helper';
import { getDataByPictureId, savePictureByEditor, saveAsPictureByEditor } from '../../api/editor';
import { uploadFile } from '@/api/open';

import { ElMessage, ElMessageBox } from 'element-plus';
import canvasEditor from './components/canvas-editor/index.vue';
import headerBar from './components/header-bar/index.vue';

export default defineComponent({
  components: {
    canvasEditor,
    headerBar,
  },

  setup() {
    const $route = useRoute();
    const $router = useRouter();
    const fabricAction = reactive({
      ctx: {} as FabricAction,
    });
    const activeObject = reactive({
      ctx: {} as fabric.Object | undefined,
    }); // 激活元素
    const allObject = reactive({
      count: 0,
    }); // 所有元素
    const name = ref(''); // 图片名称
    const suffix = ref(''); // 图片后缀
    const taskId = ref(''); // 图片任务ID
    const imgData = ref({
      json: '',
      url: '',
    }); // 图片数据

    // 激活元素
    const handleActiveObject = (obj: fabric.Object) => {
      if (obj) {
        activeObject.ctx = obj;
      } else {
        activeObject.ctx = undefined;
      }
    };

    // 元素更新
    const handleCountObject = (n: number) => {
      allObject.count = n || 0;
    };

    // 初始化实例内容
    const initFabric = () => {
      const fa = new FabricAction(
        document.getElementById('fabricCanvas') as any,
      );
      if (imgData.value.json) {
        fa.initCanvsData(imgData.value.json);
      } else if (imgData.value.url) {
        fa.addImage(imgData.value.url);
      }

      fa.on(EventNameEnum.ACTIVE_OBJECT, handleActiveObject);
      fa.on(EventNameEnum.COUNT_OBJECTS, handleCountObject);
      fabricAction.ctx = fa;
    };

    // 请求数据
    const fetchData = async () => {
      try {
        const res = await getDataByPictureId({
          pictureId: $route.params.id as string,
        });
        if (!isEmpty(res)) {
          const nameAry: string[] = res.data.pictureName.split('.');
          if (nameAry.length > 1) {
            suffix.value = `.${nameAry[nameAry.length - 1]}`;
            nameAry.pop();
          }
          name.value = nameAry.join('.');

          taskId.value = res.data.id;
          imgData.value = {
            json: res.data.editContent,
            url: res.data.originalPictureLink,
          };
          initFabric();
        }
      } catch (err) {
        console.log('请求图片数据', err);
      }
    };

    const handleUpload = (imgs: string[]) => {
      fabricAction.ctx.addImages(imgs);
    };

    onMounted(() => {
      fetchData();
    });

    onUnmounted(() => {
      fabricAction.ctx.off(EventNameEnum.ACTIVE_OBJECT, handleActiveObject);
      fabricAction.ctx.off(EventNameEnum.COUNT_OBJECTS, handleCountObject);
    });

    provide('fabricAction', fabricAction);
    provide('activeObject', activeObject);
    provide('allObject', allObject);

    // 保存
    const handleSave = async (isSaveAs?: boolean) => {
      try {
        if (!fabricAction.ctx) {
          throw new Error('fabricAction is not a object !');
        }
        const imgNew = fabricAction.ctx.exportImgData();
        const imgTrim = await getTrimPNG(imgNew);
        const file = b64toFile(imgTrim);
        const formData = new FormData();
        formData.append('files', file, file.name);
        const { data } = await uploadFile(formData);

        const params = {
          editContent: fabricAction.ctx.getCanvasJSON(),
          path: data[0].url,
          pictureName: `${name.value}${suffix.value}`,
        };

        // const a = document.createElement('a');
        // const url = window.URL.createObjectURL(file);
        // a.href = url;
        // a.download = name.value;
        // a.click();
        // window.URL.revokeObjectURL(url);

        if (isSaveAs) {
          await saveAsPictureByEditor({
            ...params,
            originalPictureId: $route.params.id as string,
          });
        } else {
          await savePictureByEditor({
            ...params,
            id: $route.params.id as string,
          });
        }
        ElMessage.success('保存成功！');
        $router.replace({
          name: 'DesignTaskManageDetail',
          params: {
            id: $route.params.taskId,
          },
          query: {
            tag: $route.params.tag, // 标签value
            styleId: $route.params.styleId, // 款式id
          },
        });
      } catch (err) {
        console.log('图片保存', err);
      }
    };

    // 返回
    const handleBack = async () => {
      await ElMessageBox.confirm(
        '真的要离开当前页面吗？注意保存图片哟～',
        '提示',
        {
          type: 'warning',
        },
      );
      $router.replace({
        name: 'DesignTaskManageDetail',
        params: {
          id: $route.params.taskId,
        },
        query: {
          tag: $route.params.tag, // 标签value
          styleId: $route.params.styleId, // 款式id
        },
      });
    };

    return {
      name,
      suffix,
      taskId,
      handleSave,
      handleUpload,
      handleBack,
    };
  },
});

