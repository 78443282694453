import { numberfuns } from 'cx-utils';
/**
 * 生成唯一ID
 * @param length - 默认长度 99
 */
export const genID = (length: number = 99) => {
  return (
    Math.random().toString().substr(3, length) + Date.now()
  );
};

/**
 * 数字格式化，超过万以 w 显示
 * @param num - 数字
 * @param digits - 小数位
 */
export const formatMillion = (num: string | number, digits: number = 1) => {
  const newNum = Number(num);
  if (Number.isNaN(newNum)) return 0;
  if (newNum < 10000) return newNum;
  const Fnum = +numberfuns.formatFloat(newNum / 10000, { pos: digits, round: true });
  return `${Fnum} w`;
};
