
import { defineComponent, inject } from 'vue';
import { Effect } from '../../type';
import FabricAction from '../../utils/action';
import { OPERATE_BTN_ENUM, OPERATE_BTN_LIST } from '../../constant';

export default defineComponent({
  setup() {
    const fabricAction = inject('fabricAction', {
      ctx: {} as FabricAction,
    });
    const activeObject = inject('activeObject', { ctx: {} as FabricAction });
    const allObject = inject('allObject', { count: 0 });

    const handleOpreate = (id: OPERATE_BTN_ENUM) => {
      switch (id) {
        case OPERATE_BTN_ENUM.COPY:
          fabricAction.ctx?.copyActiveObject();
          break;
        case OPERATE_BTN_ENUM.FLIP_HORRIZONTAL:
          fabricAction.ctx?.onFlipHorizontal();
          break;
        case OPERATE_BTN_ENUM.FLIP_VERTICAL:
          fabricAction.ctx?.onFlipVertical();
          break;
        case OPERATE_BTN_ENUM.SET_TOP:
          fabricAction.ctx?.onBringToFront();
          break;
        case OPERATE_BTN_ENUM.SET_BOTTOM:
          fabricAction.ctx?.onSendToBack();
          break;
        case OPERATE_BTN_ENUM.BRING_FORWARD:
          fabricAction.ctx?.onBringForward();
          break;
        case OPERATE_BTN_ENUM.SEND_BACKWARD:
          fabricAction.ctx?.onSendBackwards();
          break;
        case OPERATE_BTN_ENUM.DELETE:
          fabricAction.ctx?.deleteActiveObject();
          break;
        default:
          break;
      }
    };

    return {
      handleOpreate,
      activeObject,
      allObject,
    };
  },

  render() {
    return this.activeObject.ctx ? (
      <div class="operate-bar">
        {OPERATE_BTN_LIST.map((item) => {
          return ((item.id === OPERATE_BTN_ENUM.DELETE && this.allObject.count > 1)
          || item.id !== OPERATE_BTN_ENUM.DELETE)
          && (
            <el-tooltip effect={Effect.DARK} visible-arrow={true} placement="bottom" content={item.tip} >
              <div class="operate-bar__item" onClick={() => this.handleOpreate(item.id)}>
                <i class={`iconfont operate-bar__icon ${item.icon}`} />
              </div>
            </el-tooltip>
          );
        })}
      </div>
    ) : null;
  },

});
