import { ElMessage } from 'element-plus';
import { random } from 'lodash-es';

import { EventNameEnum } from '../constant';
import trimPNG from './trimPng';

// 裁剪空白区域
export const getTrimPNG = (url: string): Promise<string> => {
  return new Promise(async (resolve: any, reject: any) => {
    try {
      const dom = document.createElement('img');
      dom.src = url;
      dom.setAttribute('crossOrigin', 'Anonymous');
      dom.onload = async () => {
        try {
          const trimmedImageDataURL = trimPNG(dom);
          resolve(trimmedImageDataURL);
        } catch (err) {
          reject(null);
        }
      };
    } catch (error) {
      console.log('裁剪空白区域失败');
    }
  });
};

// 图片加载
export const loadImgPromise = (
  url: string,
): Promise<HTMLImageElement | null> => {
  return new Promise(async (resolve: any, reject: any) => {
    try {
      const dom = document.createElement('img');
      dom.src = url;
      dom.setAttribute('crossOrigin', 'Anonymous');
      dom.onload = async () => {
        try {
          resolve(dom);
        } catch (error) {
          ElMessage({
            message: '操作失败，请下载安装最新的谷歌浏览器',
            type: 'warning',
          });
          reject(null);
        }
      };
      dom.onerror = () => {
        reject(null);
      };
    } catch (error) {
      ElMessage({
        message: '操作失败，请下载安装最新的谷歌浏览器',
        type: 'warning',
      });
      reject(null);
    }
  });
};

/**
 * EventBus 具体使用
 * 1.const EventBus = new EventBus()
 * 2.eventEmitter.on('someEvent', handler)    组件 订阅事件
 * 3.eventEmitter.emit('someEvent', params)   bus 触发事件
 */
export class EventBus {
  protected handlers: Map<string, any[]>

  constructor() {
    // handlers是一个map，用于存储事件与回调之间的对应关系
    this.handlers = new Map();
  }

  // on方法用于安装事件监听器，它接受目标事件名和回调函数作为参数
  on(eventName: EventNameEnum, cb: any) {
    // 先检查一下目标事件名有没有对应的监听函数队列
    const eventList = this.handlers.get(eventName);
    if (!eventList) {
      // 如果没有，那么首先初始化一个监听函数队列
      this.handlers.set(eventName, [cb]);
    } else {
      // 把回调函数推入目标事件的监听函数队列里去
      eventList.push(cb);
    }
  }

  // emit方法用于触发目标事件，它接受事件名和监听函数入参作为参数
  emit(eventName: EventNameEnum, ...args: any[]) {
    // 检查目标事件是否有监听函数队列
    let eventList = this.handlers.get(eventName);
    if (eventList) {
      // 这里需要对 this.handlers[eventName] 做一次浅拷贝，主要目的是为了避免通过 once 安装的监听器在移除的过程中出现顺序问题
      eventList = eventList.slice();
      // 如果有，则逐个调用队列里的回调函数
      eventList.forEach((callback) => {
        callback(...args);
      });
    }
  }

  // 移除某个事件回调队列里的指定回调函数
  off(eventName: EventNameEnum, cb: any) {
    const callbacks = this.handlers.get(eventName) || [];
    // cb 避免使用匿名函数
    const index = callbacks.indexOf(cb);
    if (index !== -1) {
      callbacks.splice(index, 1);
      return true;
    }
    return false;
  }

  // 为事件注册单次监听器
  once(eventName: EventNameEnum, cb: any) {
    // 对回调函数进行包装，使其执行完毕自动被移除
    const wrapper = (...args: any[]) => {
      cb(...args);
      this.off(eventName, wrapper);
    };
    this.on(eventName, wrapper);
  }
}

// base64转文件
export const b64toFile = (dataurl: any) => {
  const arr = dataurl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const suffix = mime.split('/')[1];
  const bstr = window.atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  // eslint-disable-next-line
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  const file: any = new File([u8arr], `${Date.now()}_${random(1, 9999)}.${suffix}`, { type: mime });
  return file;
};
