
import { defineComponent } from 'vue';
import operateBar from '../operate-bar/index.vue';

export default defineComponent({
  components: {
    operateBar,
  },

  render() {
    return (
      <div>
        <div id="auto_content" class="container">
          <operate-bar />
          <div id="main_content" class="wrap">
            <canvas id="fabricCanvas" style={{ boxSizing: 'border-box', border: '1px solid #eee' }}/>
          </div>
        </div>
      </div>
    );
  },

});
